import React, { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { object, node, string, oneOf, oneOfType } from 'prop-types';
import cn from 'classnames';

import css from './index.scss';

function Tooltip(props) {
  const { text, containerClassName, triggerClassName, className, children, style, position } = props;

  const [active, setActive] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({});
  const wrapperRef = useRef(null);
  const tooltipRef = useRef(null);
  const portalTargetRef = useRef(document.getElementById('container'));

  const setPosition = () => {
    if (wrapperRef.current) {
      const rect = wrapperRef.current.getBoundingClientRect();
      const positionLeft = rect.left + window.scrollX;
      const positions = {
        top: {
          left: positionLeft,
          top: rect.top + window.scrollY - (tooltipRef.current?.offsetHeight || 0) - 6,
        },
        bottom: {
          left: positionLeft,
          top: rect.bottom + window.scrollY + 5,
        },
      };

      setTooltipPosition(positions[position]);
    }
  };

  useEffect(() => {
    if (active) setPosition();
  }, [active]);

  return (
    <div
      ref={wrapperRef}
      className={cn(css.tooltipContainer, containerClassName)}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      {children && <div className={cn(css.tooltipTrigger, triggerClassName)}>{children}</div>}

      {active &&
        createPortal(
          <div
            ref={tooltipRef}
            className={cn(css.tooltip, className, css[position])}
            style={{
              left: tooltipPosition.left,
              top: tooltipPosition.top,
              ...style,
            }}
            role="tooltip"
          >
            {text}
          </div>,
          portalTargetRef.current,
        )}
    </div>
  );
}

Tooltip.defaultProps = {
  children: undefined,
  className: '',
  containerClassName: 'defined',
  position: 'top',
  style: undefined,
  text: '',
  triggerClassName: '',
};

Tooltip.propTypes = {
  children: node,
  className: string,
  containerClassName: string,
  position: oneOf(['top', 'bottom']),
  style: object,
  text: oneOfType([string, node]),
  triggerClassName: string,
};

export default Tooltip;
