exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".tooltipContainer-3vpTU {\n  position: relative;\n}\n\n.tooltipTrigger-2HGsa {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n\n.tooltip-2bE3L {\n  position: absolute;\n  pointer-events: none;\n  z-index: 1;\n  padding: 12px;\n  border-radius: 4px;\n  background-color: #fff;\n  -webkit-box-shadow: 0 4px 12px 0 #202046;\n  box-shadow: 0 4px 12px 0 #202046;\n  color: #3d3d41;\n  font-size: 0.8rem;\n  -webkit-animation: fadeIn-1kS1k 0.5s;\n          animation: fadeIn-1kS1k 0.5s;\n}\n\n.tooltip-2bE3L:after {\n    position: absolute;\n    left: 2px;\n    border-width: 6px;\n    border-style: solid;\n    content: '';\n  }\n\n.tooltip-2bE3L.top-3xewZ:after {\n    bottom: -12px;\n    border-color: #fff transparent transparent transparent;\n  }\n\n.tooltip-2bE3L.bottom-3tc87:after {\n    top: -12px;\n    border-color: transparent transparent #fff transparent;\n  }\n\n@-webkit-keyframes fadeIn-1kS1k {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n\n@keyframes fadeIn-1kS1k {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"tooltipContainer": "tooltipContainer-3vpTU",
	"tooltipTrigger": "tooltipTrigger-2HGsa",
	"tooltip": "tooltip-2bE3L",
	"fadeIn": "fadeIn-1kS1k",
	"top": "top-3xewZ",
	"bottom": "bottom-3tc87"
};